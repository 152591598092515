
import { defineComponent, computed } from 'vue'
import Api from '../api'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { HSIcon } from '@healthscholars/healthscholars-vue'
import { Flatfile, PartialRejection, RecordError } from '@flatfile/sdk'
import { useAuthStore, useUsersStore } from '../store'
import { getInstance } from '../auth'

export default defineComponent({
  name: 'BulkImportComponent',
  components: {
    HSIcon,
  },
  setup() {
    const authStore = useAuthStore()
    const usersStore = useUsersStore()
    return {
      user: computed(() => authStore.user),
      role: computed(() => authStore.getRole),
      accountId: computed(() => authStore.getAccountId),
      setImportUsers: usersStore.setImportUsers,
      startImportUsers: usersStore.startImportUsers,
    }
  },
  methods: {
    importFile(): void {
      let errors: RecordError[] = []
      const { setImportUsers, startImportUsers, user, role, accountId } = this
      Flatfile.requestDataFromUser({
        embedId: process.env.VUE_APP_FLATFILE_EMBED_ID,
        user: { id: this.user.userId, name: this.user.nickname, email: this.user.email },
        token: async (): Promise<string> => {
          const authService = getInstance()
          const accessToken = await authService.getTokenSilently()
          const res = await Api.getFlatfileToken(
            {
              id: this.user.userId,
              name: this.user.nickname,
              email: this.user.email,
            },
            accessToken
          )
          return res
        },
        async onData(chunk, next) {
          const { records } = chunk
          try {
            const formatedUsers = records.map((record) => ({
              actor: {
                id: user.userId,
                role: role,
                accountId: accountId,
              },
              action: {
                user: {
                  email: record.data.email?.toString() || '',
                  password: record.data.password?.toString() || '',
                  given_name: record.data.given_name?.toString() || '',
                  family_name: record.data.family_name?.toString() || '',
                  nickname: record.data.nickname?.toString() || '',
                  role: record.data.role?.toString() || '',
                  institution_id: record.data.facility?.toString() || '',
                  account_id: record.data.account?.toString() || '',
                  department_id: record.data.department?.toString() || '',
                  access: [record.data.account?.toString() || ''],
                },
              },
            }))
            if (formatedUsers.length > 0) {
              setImportUsers(formatedUsers)
            }
            if (errors.length > 0) {
              next(new PartialRejection(errors))
            } else {
              next()
            }
          } catch (e) {
            next(new PartialRejection(errors))
          }
        },
        onComplete() {
          startImportUsers()
        },
      })
    },
  },
})
