
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SelectComponent',
  data() {
    return {
      selected: '',
    }
  },
  props: {
    valueHandler: { type: String, default: '' },
    dataTest: { type: String, default: '' },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: '' },
    name: { type: String, default: 'select' },
    posibleItems: {
      type: Array as () => Array<{ id: string; value: string }>,
      default: () => [],
    },
  },
  watch: {
    selected(value) {
      this.$emit('onChange', value)
    },
    valueHandler(value) {
      this.selected = value
    },
  },
  mounted() {
    this.selected = this.valueHandler || ''
  },
})
