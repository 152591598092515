
import { defineComponent, computed } from 'vue'
import { useUsersStore } from '../store'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { HSIcon, HSLoading } from '@healthscholars/healthscholars-vue'
export default defineComponent({
  components: {
    HSIcon,
    HSLoading,
  },
  name: 'ImportModalComponent',
  setup() {
    const usersStore = useUsersStore()
    return {
      importedUsers: computed(() => usersStore.importedUsers),
      closeModal: () => usersStore.closeImportUsers(),
      canClose: computed(() => usersStore.getIsFetchingUsers),
    }
  },
  methods: {
    iconName(status: string) {
      switch (status) {
        case 'success':
          return 'check'
        case 'error':
          return 'close'
        default:
          return 'cached'
      }
    },
    iconColor(status: string) {
      switch (status) {
        case 'success':
          return '#4caf50'
        case 'error':
          return '#f44336'
        default:
          return 'black'
      }
    },
  },
})
