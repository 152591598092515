
import {
  HSStyling,
  HSNavbar,
  HSIcon,
  HSButton,
  HSAlertStack,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'
import { defineComponent, computed } from 'vue'
import { useAuthStore, useUsersStore, useNotificationStore } from './store'
import { AuthUser } from './types'
import ImportModal from './components/ImportModalComponent.vue'
import SelectComponent from './components/SelectComponent.vue'
import SidebarEditForm from './components/SidebarEditForm/SidebarEditForm.vue'
import SidebarCreateForm from './components/SidebarCreateForm/SidebarCreateForm.vue'

export default defineComponent({
  name: 'App',
  components: {
    HSNavbar,
    HSStyling,
    HSIcon,
    HSAlertStack,
    ImportModal,
    HSButton,
    SelectComponent,
    SidebarEditForm,
    SidebarCreateForm,
  },
  computed: {
    loginButtonLabel(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$auth.isAuthenticated ? 'Logout' : 'Login'
    },
    appList() {
      const apps = []
      const userManagerURI = process.env.VUE_APP_LANDING_PAGE_URI
      const customerSuccessManagerURI = process.env.VUE_APP_CUSTOMER_SUCCESS_PAGE_URI

      apps.push(userManagerURI, customerSuccessManagerURI)

      return apps
    },
  },
  data() {
    return {
      landingPageUri: process.env.VUE_APP_LANDING_PAGE_URI,
      customerSuccessManagerURI: process.env.VUE_APP_CUSTOMER_SUCCESS_PAGE_URI,
      showUserInfo: false,
      selected: '',
    }
  },
  methods: {
    setLogin() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this.$auth.isAuthenticated) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$auth.loginWithRedirect()
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.$auth.logout({
          returnTo: window.location.origin,
        })
      }
    },
    showInfo() {
      this.showUserInfo = !this.showUserInfo
    },
  },
  setup() {
    const authStore = useAuthStore()
    const usersStore = useUsersStore()
    const notificationStore = useNotificationStore()

    return {
      accountCode: computed(() => authStore.getAccountCode),
      getRole: computed(() => authStore.getRole),
      isSuperAdmin: computed(() => authStore.isSuperAdmin),
      isAdmin: computed(() => authStore.isAdmin),
      isLearner: computed(() => authStore.isLearner),
      setAccountCode: (id: string) => authStore.setActiveAccountCode(id),
      setUser: authStore.setUser,
      user: computed(() => authStore.user),
      getAccounts: computed(() => usersStore.getAccounts),
      isImporting: computed(() => usersStore.getIsImportingUsers),
      setRef: computed(() => notificationStore.setRef),
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$auth.$watch('user', (user: AuthUser) => {
      if (user) {
        this.setUser(user)
      }
    })
    this.setRef(this.$refs.alert)
  },
})
