
import { defineComponent, computed, ref, onMounted } from 'vue'
import { Role } from '@/types'
import {
  HSDataTable,
  HSButton,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@healthscholars/healthscholars-vue'
import BulkImportComponent from '../components/BulkImportComponent.vue'
import { useUsersStore, useAuthStore, usePanelStore } from '../store'

export default defineComponent({
  name: 'UsersTableView',
  components: {
    HSDataTable,
    HSButton,
    BulkImportComponent,
  },
  data: () => {
    return {
      dataTableHeaders: [
        {
          label: 'First name',
          key: 'firstname',
          sortable: true,
          visible: true,
          searchable: true,
        },
        {
          label: 'Last name',
          key: 'lastname',
          sortable: true,
          visible: true,
          searchable: true,
        },
        {
          label: 'Username',
          key: 'nickname',
          sortable: true,
          visible: true,
          searchable: true,
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
          visible: true,
          searchable: true,
        },
        {
          label: 'Account',
          key: 'sf_account',
          sortable: true,
          visible: true,
          searchable: true,
          filterable: true,
          options: [],
        },
        {
          label: 'Facility',
          key: 'facility',
          sortable: true,
          visible: true,
          searchable: true,
        },
        {
          label: 'Role',
          key: 'role',
          visible: true,
          searchable: true,
          filterable: true,
          options: [
            { id: 1, value: 'Learner' },
            { id: 2, value: 'Admin' },
          ],
        },
      ],
      dataTableSortBy: [
        'firstname',
        'lastname',
        'nickname',
        'email',
        'sf_account',
        'facility',
        'role',
      ],
      params: {
        search: '',
        sort: {},
        blocked: false,
        accountId: '',
        role: '',
      },
    }
  },
  setup() {
    const usersStore = useUsersStore()
    const authStore = useAuthStore()
    const panelStore = usePanelStore()
    const tableRef = ref()

    onMounted(() => {
      if (tableRef.value) {
        panelStore.setTableRef(tableRef)
      }
    })

    return {
      authRole: computed(() => authStore.getRole),
      isSuperAdmin: computed(() => authStore.isSuperAdmin),
      isAdmin: computed(() => authStore.isAdmin),
      isLearner: computed(() => authStore.isLearner),
      authId: computed(() => authStore.getId),
      getAccounts: computed(() => usersStore.getAccounts),
      getUsers: computed(() => usersStore.getUsers),
      setActivePage: (page: number) => usersStore.setActivePage(page),
      deleteUser: (id: string) => usersStore.deleteUser(id),
      isLoading: computed(() => usersStore.getIsLoadingUsers),
      getActivePage: computed(() => usersStore.getActivePage),
      getPageTotal: computed(() => usersStore.getPageTotal),
      requestUsers: (
        actor: {
          role: Role
          institutionId: string
          id: string
        },
        search?: string,
        blocked?: boolean,
        accountId?: string,
        roleSearch?: string,
        sort?: {
          firstmame?: 'asc' | 'desc'
          email?: 'asc' | 'desc'
          lastmame?: 'asc' | 'desc'
          nickname?: 'asc' | 'desc'
          sf_account?: 'asc' | 'desc'
          facility?: 'asc' | 'desc'
        }
      ) => usersStore.requestUsers(actor, search, blocked, accountId, roleSearch, sort),
      panelStore,
      tableRef,
    }
  },
  computed: {
    routeParamsFilter(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.$route.params.filter
    },
  },
  methods: {
    searchUsers(search: string) {
      this.setActivePage(1)
      this.params.search = search
      this.requestUsers(
        {
          role: Role.Learner,
          institutionId: '',
          id: '',
        },
        search,
        this.params.blocked,
        this.params.accountId,
        this.params.role,
        this.params.sort
      )
    },
    changePage(page: number) {
      if (this.getActivePage !== page && page >= 1 && page <= this.getPageTotal) {
        this.setActivePage(page)
        this.requestUsers(
          {
            role: Role.Learner,
            institutionId: '',
            id: '',
          },
          this.params.search,
          this.params.blocked,
          this.params.accountId,
          this.params.role,
          this.params.sort
        )
      }
    },
    sortUsers(sortBy: { [key: string]: string }) {
      const filteredElement = Object.entries(sortBy).find((element) => element[1] !== 'sort')
      this.params.sort = {
        [filteredElement?.[0] as string]: filteredElement?.[1] === 'north' ? 'asc' : 'desc',
      }
      this.requestUsers(
        {
          role: Role.Learner,
          institutionId: '',
          id: '',
        },
        this.params.search,
        this.params.blocked,
        this.params.accountId,
        this.params.role,
        {
          [filteredElement?.[0] as string]: filteredElement?.[1] === 'north' ? 'desc' : 'asc',
        }
      )
    },
    filterUsers(filters: { key: string; oValue: string }[]) {
      const localRole = filters.find((filter) => filter.key === 'role')
      if (localRole) {
        this.params.role = localRole.oValue
      } else {
        this.params.role = ''
      }
      const localAccount = filters.find((filter) => filter.key === 'sf_account')
      let getAccountId: { id: string; value: string } | undefined = undefined
      if (localAccount) {
        getAccountId = this.getAccounts.find((account) => account.value === localAccount.oValue)
        this.params.accountId = getAccountId ? getAccountId.id : ''
      } else {
        this.params.accountId = ''
      }
      this.requestUsers(
        {
          role: Role.Learner,
          institutionId: '',
          id: '',
        },
        this.params.search,
        this.params.blocked,
        getAccountId ? getAccountId.id : '',
        localRole ? localRole.oValue : '',
        this.params.sort
      )
    },
    deletedUsers() {
      const localBlocked = !this.params.blocked
      this.params.blocked = localBlocked
      this.requestUsers(
        {
          role: Role.Learner,
          institutionId: '',
          id: '',
        },
        this.params.search,
        localBlocked,
        this.params.accountId,
        this.params.role,
        this.params.sort
      )
    },
  },
  watch: {
    getAccounts(accounts) {
      this.dataTableHeaders[4].options = [...accounts]
    },
    isSuperAdmin(isSuperAdmin) {
      if (isSuperAdmin) {
        this.dataTableHeaders[6].options = [
          { id: 1, value: 'Learner' },
          { id: 2, value: 'Admin' },
          { id: 3, value: 'Super Admin' },
        ]
      }
    },
  },
})
